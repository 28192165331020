import "../sass/7-bundles/index.scss";

async function loadTao() {
  import("../scripts/tao/tao.js");
  console.log("tao loaded");
}
async function loadContact() {
  import("../scripts/contactBtn.js");
  console.log("contactBtn loaded");
}
async function loadAnimation() {
  import("../scripts/animations.js");
  console.log("animation loaded");
}
async function loadForm() {
  import("../scripts/form.js");
  console.log("form loaded");
}
async function loadSlider() {
  import("../scripts/slider.js");
  console.log("slider loaded");
}
async function loadRelaciones() {
  import("../scripts/relaciones.js");
  console.log("relaciones loaded");
}

window.addEventListener("load", async function loadModules() {
  await loadTao();
  await loadAnimation();
  await loadContact();
  await loadRelaciones();
  await loadForm();
  await loadSlider();
});
